import React from "react";
import "./App.css";
import "antd/dist/antd.css";
import { Login } from "./Pages/Login/Login";
import { Navbar } from "./Components/Navbar/Nav";
import { SideNavigation } from "./Components/SideNavigation/SideNavigation";
import { AddInstructor } from "./Pages/AddInstructors/AddInstructor";
import { MainLayout } from "./MainLayout/MainLayout";
import { ApplicationContextProvider } from "./Services/ApplicationContext";

function App() {
  return (
    <div className="App">
      <ApplicationContextProvider
        serverSideUrl={"http://147.8.117.117:8000"}
        // serverSideUrl={"http://backend:8000"}
        // serverSideUrl={"http://18.163.210.226:8000"}
        apiPrefix={"v1.0"}
      >
        <MainLayout />
      </ApplicationContextProvider>

      
    </div>
  );
}

export default App;
 
